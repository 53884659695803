<template>
  <k-input
    v-if="showNative"
    :value="date"
    :type="type"
    :min="minInputFormat"
    :max="maxInputFormat"
    @input="toISOString"
    :disabled="disabled"
    :required="required"
    :error="localError || error"
    class="date-input--native"
    data-cy="date-input"
  />
  <k-input
    v-else
    :disabled="disabled"
    :required="required"
    data-cy="date-input-flatpickr"
  >
    <flat-pickr
      v-model="date"
      :config="flatPickrConfig"
      @on-change="onFlatPickrChange"
      :disabled="disabled"
      @input="onFlatPickrInput"
      :required="required"
    />
    <template #suffix v-if="!disabled">
      <i class="fal fa-calendar"></i>
    </template>
  </k-input>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component';
import moment from 'moment-timezone';

/* eslint-disable import/no-extraneous-dependencies */
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/plugins/monthSelect/style.css';
/* eslint-enable import/no-extraneous-dependencies */
import { detectBrowser } from '@utils';
import KInput from './input.vue';

const today = new Date();

export default {
  name: 'k-date-input',
  components: {
    KInput,
    FlatPickr,
  },
  props: {
    value: [String, Date],
    min: {
      value: [String, Date],
      default: 'totalMin',
    },
    max: {
      value: [String, Date],
      default: 'totalMax',
    },
    dateOnly: {
      value: Boolean,
      default: false,
    },
    type: {
      value: String,
      default: 'date',
    },
    default: {
      value: [String, Date],
    },
    required: {
      value: Boolean,
    },
    disabled: {
      value: Boolean,
    },
    enableDay: {
      value: Object,
    },
    showFlatPickr: {
      value: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      date: null,
      localError: false,
    };
  },

  watch: {
    value: {
      handler(val) {
        this.date = val && this.getInputFormat(val);
      },
      immediate: true,
    },
  },

  computed: {
    showNative() {
      if (this.showFlatPickr) {
        return false;
      }
      return detectBrowser.isChromium() || detectBrowser.isMobileDevice();
    },
    minInputFormat() {
      return this.getMinMax(this.min);
    },
    maxInputFormat() {
      return this.getMinMax(this.max);
    },

    minMaxDate() {
      return {
        min: this.min && new Date(this.minInputFormat),
        max: this.max && new Date(this.maxInputFormat),
      };
    },

    defaultDate() {
      if (this.default) {
        switch (this.default) {
          case 'min':
            return this.minInputFormat;
          case 'max':
            return this.minInputFormat;
          default:
            return this.getInputFormat(this.default);
        }
      }
      return undefined;
    },

    flatPickrConfig() {
      const dateFormat = this.type === 'month' ? 'Y-m' : 'Y-m-d';
      const altFormat = this.type === 'month' ? 'F Y' : 'm/d/Y';
      const plugins = this.type === 'month' ? [
        new MonthSelectPlugin({
          dateFormat: 'Y-m',
          altFormat: 'F Y',
          shorthand: true,
        }),
      ] : [];

      const dateInfo = this.enableDay;
      const enable = [
        // eslint-disable-next-line func-names
        function (date) {
          let enableDate;
          if (dateInfo) {
            enableDate = dateInfo.frequency === 'monthly' ? Number(date.getDate()) === Number(dateInfo.day) : Number(date.getDay()) === Number(dateInfo.day);
          } else {
            enableDate = true;
          }
          return enableDate;
        },
      ];
      return {
        minDate: this.minInputFormat,
        maxDate: this.maxInputFormat,
        dateFormat,
        altFormat,
        altInput: true,
        allowInput: !this.disabled,
        altInputClass: 'k-input__input-field',
        plugins,
        defaultDate: this.defaultDate,
        enable,
      };
    },
  },

  methods: {
    getInputFormat(timestamp) {
      if (!timestamp || (typeof timestamp !== 'string' && !(timestamp instanceof Date))) {
        return undefined;
      }

      const date = moment(timestamp).toDate();
      const localeDate = date.toLocaleDateString('en-US');
      const dateAsArray = localeDate.split('/');
      const year = String(dateAsArray[2]).padStart(4, '0');
      const mo = String(dateAsArray[0]).padStart(2, '0');

      if (this.type === 'month') {
        return `${year}-${mo}`;
      }

      const day = String(dateAsArray[1]).padStart(2, '0');
      return `${year}-${mo}-${day}`;
    },

    getMinMax(value) {
      if (value === undefined) {
        return undefined;
      }
      if (value === 'today') {
        return this.getInputFormat(new Date());
      }
      if (value === 'tomorrow') {
        const tomorrow = new Date((new Date(today)).setDate(today.getDate() + 1));
        return this.getInputFormat(tomorrow);
      }
      if (value === 'totalMin') {
        const yearsAgo12 = new Date(today);
        yearsAgo12.setHours(0, 0, 0);
        yearsAgo12.setFullYear(today.getFullYear() - 12, 0, 1);
        return this.getInputFormat(yearsAgo12);
      }
      if (value === 'totalMax') {
        const yearsFromNow12 = new Date(today);
        yearsFromNow12.setHours(23, 59, 59);
        yearsFromNow12.setFullYear(today.getFullYear() + 12, 11, 31);
        return this.getInputFormat(yearsFromNow12);
      }
      return this.getInputFormat(value);
    },

    toISOString(dateString) {
      const date = dateString || this.defaultDate;
      if (date) {
        if (this.type === 'month') {
          this.$emit('input', `${date}-01`);
        } else {
          const d = new Date(date);
          if (this.dateOnly) {
            this.$emit('input', d.toISOString().split('T')[0]);
          } else {
            const adjustedDate = new Date(d.getTime() + new Date().getTimezoneOffset() * 60000);
            this.$emit('input', adjustedDate.toISOString());
          }
        }
        if (date !== this.date) {
          this.date = date;
        }
      } else {
        this.$emit('input', undefined);
      }
      this.emitError(date);
    },

    emitError(value) {
      if (!value) {
        this.localError = !this.required;
      } else {
        const dateValue = new Date(value);
        const hasMinError = this.min && this.minMaxDate.min > dateValue;
        const hasMaxError = this.max && this.minMaxDate.max < dateValue;
        this.localError = hasMinError || hasMaxError;
      }
      this.$emit('error', this.localError);
    },

    onFlatPickrChange(date, value) {
      this.toISOString(value);
    },
    onFlatPickrInput(value) {
      this.$emit('input', value);
    },
  },
};

</script>

<style scoped>
.date-input--native {
  min-width: 141px; /* android, Chrome */
}
</style>
