<template>
  <div class="checkbox-wrapper" data-cy="dependents-checkbox-list">
    <p v-if="withTitle">Children</p>
    <k-radio
      v-for="dependent in dependents"
      :key="`${dependent.nameAge} ${dependent.id}`"
      v-model="localState"
      :id="'dependent_' + dependent.id"
      :value="dependent.id"
      :disabled="disableDependent(dependent.legupChildId) || isTooOld(dependent.firstName)"
    >
      {{ ageDisplay ? dependent.nameAge : dependent.firstNameDob }}
      <dependent-age-warning v-if="isTooOld(dependent.firstName)"/>
      <dependent-joined-waitlist v-if="disableDependent(dependent.legupChildId)" />
      <enrolled-dependent-warning
          :center-name="dependent.enrolledCenterName"
          v-if="isEnrolled(dependent)">
      </enrolled-dependent-warning>
    </k-radio>
    <p v-if="!dependents.length && !loadingDependents"> no children </p>
    <system-button v-if="showManage"
      small
      style-type="secondary"
      fa-icon="pencil"
      @click="onOpenModal"
      class="add-button"
    >
      Manage children
    </system-button>

    <div class="skeleton-loader" v-if="loadingDependents"></div>

    <manage-dependents-modal :is-open="showManageModal" @saved="onSaved" @hide="onHideModal"/>
  </div>
</template>

<script>
import SystemButton from '@components/buttons/system-button.vue';

import KRadio from '@components/inputs/radio.vue';
import DependentsMixin from '@mixins/dependents';
import ageWarning from '@mixins/ageWarning';
import ManageDependentsModal from './manage-dependents-modal.vue';
import dependentAgeWarning from './dependent-provider-age-warning.vue';
import dependentJoinedWaitlist from './dependent-joined-waitlist.vue';
import EnrolledDependentWarning from './enrolled-dependent-warning.vue';

export default {
  components: {
    KRadio,
    ManageDependentsModal,
    SystemButton,
    dependentAgeWarning,
    dependentJoinedWaitlist,
    EnrolledDependentWarning,
  },

  model: {
    prop: 'modelValue',
    event: 'change',
  },
  mixins: [ageWarning, DependentsMixin],
  props: {
    dependentsWithSpots: {
      type: Array,
      default() {
        return [];
      },
    },
    olderChildren: {
      type: Array,
      default() {
        return [];
      },
    },
    ageDisplay: {
      type: Boolean,
      default: true,
    },
    withTitle: {
      type: Boolean,
      default: false,
    },
    showManage: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: Number,
    },
    centerId: {
      type: Number,
    },
    preSelectedDependentId: {
      type: Number,
    },
  },

  data() {
    return {
      showManageModal: false,
    };
  },

  computed: {
    localState: {
      get() {
        return !!this.modelValue || this.modelValue === 0 ? this.modelValue : undefined;
      },
      set(localState) { this.emitChange(localState); },
    },
  },

  methods: {
    onSaved(ids) {
      const last = ids.pop();
      if (last) {
        this.emitChange(last);
        this.$el.dispatchEvent(new Event('change', { bubbles: true }));
        this.onHideModal();
      }
    },

    onOpenModal() {
      this.showManageModal = true;
    },

    onHideModal() {
      this.showManageModal = false;
    },

    emitChange(value) {
      this.$emit('change', value || value === 0 ? Number(value) : undefined);
    },

    disableDependent(dependentId) {
      return this.dependentsWithSpots.includes(dependentId);
    },

    isEnrolled(dependent) {
      return !!dependent.isEnrolled;
    },
  },

  watch: {
    preSelectedDependentId: {
      immediate: true,
      async handler(id) {
        if (id) {
          this.localState = id;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-wrapper::v-deep {
  & > div {
    margin-bottom: var(--grid-unit);
  }

  & > p {
    margin-bottom: 5px;
  }
}

.add-button {
  margin-top: var(--grid-unit);
}

@keyframes skeleton-animation {
  0% {
    transform: translateX(-100%);
  }

  30% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.skeleton-loader {
  height: calc(var(--grid-unit) * 3);
  position: relative;
  background: var(--gray-10);
  border-radius: 2px;
  overflow: hidden;
  margin-bottom: calc(var(--grid-unit) / 2);

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: translateX(-100%);
    content: '';
    background: linear-gradient(0.25turn, transparent, #fff, transparent);
    pointer-events: none;
    opacity: 0.8;
    animation: skeleton-animation 2.5s infinite;
  }
}
</style>
