var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"k-select-wrapper",class:{
    'k-select-wrapper--error': _vm.error,
    'k-select-wrapper--small': _vm.small,
  }},[(_vm.faIcon)?_c('i',{staticClass:"k-select__icon fal",class:`fa-${_vm.faIcon}`}):_vm._e(),_vm._v(" "),_c('select',_vm._g({staticClass:"k-select",class:{
      'k-select--placeholder': _vm.isPlaceholder,
      'k-select--with-icon': _vm.faIcon,
    },attrs:{"disabled":_vm.isDisabled},domProps:{"value":_vm.value}},_vm.inputListeners),[(!!_vm.placeholder)?_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("\n      "+_vm._s(_vm.placeholder)+"\n    ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.optionsAsObjects),function(option,index){return _c('option',{key:option.value + '-' + index,domProps:{"value":option.value,"selected":option.value === _vm.value}},[_vm._v("\n      "+_vm._s(option.label)+"\n    ")])})],2),_vm._v(" "),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"k-select__chevron font-body-small"},[_c('i',{staticClass:"fal fa-chevron-down"})])
}]

export { render, staticRenderFns }