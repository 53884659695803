<template>
  <div class='k-select-wrapper'
    :class="{
      'k-select-wrapper--error': error,
      'k-select-wrapper--small': small,
    }"
  >
    <i v-if="faIcon"
      class="k-select__icon fal"
      :class="`fa-${faIcon}`">
    </i>
    <select
      :disabled="isDisabled"
      :value="value"
      v-on="inputListeners"
      class='k-select'
      :class="{
        'k-select--placeholder': isPlaceholder,
        'k-select--with-icon': faIcon,
      }"
    >
      <option v-if="!!placeholder" value="" disabled selected>
        {{placeholder}}
      </option>
      <option
        v-for="(option, index) in optionsAsObjects"
        :key="option.value + '-' + index"
        :value="option.value"
        :selected="option.value === value"
      >
        {{ option.label}}
      </option>
    </select>
    <div class="k-select__chevron font-body-small"><i class="fal fa-chevron-down"></i></div>
  </div>
</template>

<script>
export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
    },
    faIcon: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    defaultValue: {
      type: [String, Number],
      validator(value) {
        return !value || value === 'none' || this.options.includes(value);
      },
    },
    error: {
      type: Boolean,
    },
    placeholder: {
      type: [String, Number],
    },
    small: {
      type: Boolean,
    },
  },

  mounted() {
    const optionValues = this.optionsAsObjects.map((o) => o.value);
    /* eslint-disable max-len */
    if ((!this.value || this.value === '' || !optionValues.includes(this.value)) && this.defaultValue !== 'none') {
      this.$emit('input', !this.placeholder ? this.defaultValue || this.optionsAsObjects[0]?.value : undefined);
    }
    /* eslint-enable max-len */
  },

  computed: {
    inputListeners() {
      const vm = this;
      return {
        ...this.$listeners,
        change(event) {
          vm.$emit('change', event.target.value);
        },
        input(event) {
          vm.$emit('input', event.target.value);
        },
      };
    },

    optionsAsObjects() {
      return this.options.map((option) => {
        if (typeof option === 'object') {
          return option;
        }
        return { label: option, value: option };
      });
    },

    isPlaceholder() {
      return this.placeholder && this.value === undefined;
    },

    valueLabel() {
      return this.optionsAsObjects.find((o) => o.value === this.value)?.label || this.value;
    },

    hasPrefix() {
      return this.$slots.prefix;
    },
  },
};
</script>

<style lang="scss" scoped>
.k-select-wrapper {
  position: relative;
  display: inline-block;
  line-height: 1;

  .k-select {
    width: 100%;
    height: calc(var(--grid-unit) * 5);
    /* stylelint-disable declaration-empty-line-before */
    $padding-top: var(--grid-unit);
    $padding-right: calc(var(--grid-unit) * 4);
    $padding-bottom: var(--grid-unit);
    $padding-left: calc(var(--grid-unit) * 1.5);
    padding: $padding-top $padding-right $padding-bottom $padding-left;
    /* stylelint-enable declaration-empty-line-before */
    border: 1px solid var(--gray-80);
    border-radius: var(--radius);
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &--with-icon {
      padding-left: 40px;
    }

    &__icon {
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(var(--grid-unit) * 1.5);
      padding: 0;
      line-height: 48px;
      pointer-events: none;
    }

    &::-ms-expand {
      display: none;
    }

    &--placeholder {
      color: var(--gray-30);
    }

    &__chevron {
      position: absolute;
      top: 0;
      right: calc(var(--grid-unit) * 1.5);
      bottom: 0;
      padding: 0;
      line-height: 40px;
      pointer-events: none;
    }
  }

  &--error {
    color: var(--rust-50);

    .k-select {
      border-color: var(--rust-50);
      box-shadow: 0 0 0 2px var(--rust-0);
    }
  }

  &--small {
    & .k-select {
      height: calc(var(--grid-unit) * 3);
      padding: 0 calc(var(--grid-unit) * 3) 0 var(--grid-unit);
      font-size: var(--font-size-0);
      line-height: 24px;
      color: var(--viridian-80);
      background: var(--viridian-5);
      border: none;

      &__chevron {
        right: var(--grid-unit);
        line-height: 24px;
        color: var(--viridian-80);
      }
    }
  }

  .k-select:focus {
    box-shadow: 0 0 0 2px var(--input-focus-color);
  }
}

</style>
