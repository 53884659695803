<template>
  <small class="warning-text">
    This child is currently enrolled at {{centerName}}.
  </small>
</template>

<style lang="scss" scoped>
.warning-text {
  color: red;
}
</style>
<script>

export default {
  props: {
    centerName: {
      type: String,
      default: 'this center',
    },
  },

};
</script>
