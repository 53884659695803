export default {
  props: {
    olderChildren: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  methods: {
    isTooOld(name) {
      return this.olderChildren.includes(name);
    },
  },
};
